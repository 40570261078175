.nav-bar {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: space-between;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 600px;
    overflow: hidden;
    border-bottom: 3px solid #a8dadc;
    background-color: rgb(255, 255, 255);
}

.nav-top {
    display: flex;
    background-color: #14213d;
    border-bottom: 3px solid #e63946;

}

.name-wrapper {
    width: 80%;
}


.nav-contact-links {
    display: flex;
    align-items: center;
}

.links-block {
    display: flex;
    margin-left: 40px;
}

.link-button-nav {
    color: rgb(218, 218, 218);
    transition: color 0.10s ease-in-out;
}

.link-button-nav:hover {
    color: white;
}

.resume-popup {
    display: flex;
    align-items: center;
}

.resume-text {
    color: white;
    font-weight: 400;
    opacity: 0;
    margin-top: 0;
    margin-bottom: 0;
    margin-left: 20px;
    position: absolute;
    z-index: 1;
    transition: transform 0.10s ease-in-out, opacity 0.10s ease-in-out;
    pointer-events: none
}

.resume-icon {
    z-index: 2;
    display: flex;
    align-items: center;
}

.resume-icon:hover .resume-text {
    transform: translate(60px, 0);
    opacity: 1;

}

.title {
    color: white;
    margin-left: 15%;
    font-weight: 400;
}


.night {
    background-color: #14213d;
    color: white;
    border-bottom: 3px solid white;

}

.light {
    color: #14213d;
}

.my-name {
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
    margin-left: 15%;
    margin-top: 20px;
    margin-bottom: 0;
    font-size: 60px;
    width: 100%;
    color: white;
}


.profile-img {
    height: 450px;
    width: 450px;
    border-radius: 50%;
    box-shadow: -150px 200px 1px -3em #e63946,
        210px 140px 1px -10em #f9c74f, 200px -200px 1px#a8dadc;
    margin-top: -30px;
}


.about-block {
    display: flex;
    justify-content: space-between;
    margin-right: 15%;
    background-color: rgb(255, 255, 255)
}

.text-block {
    margin-left: 15%;
    max-height: 420px;
    z-index: 2;
    overflow-y: auto;
}

.welcome-text {
    color: #14213d;
    padding-bottom: 15px;
    border-bottom: 1px solid #f9c74f;
}

.about-me-section {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    margin-right: 100px;
    max-width: 1000px;
    overflow-y: auto;
    font-size: 1.5rem;
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
    color: #14213d;
}
