.contact-section {
    display: flex;
    flex-direction: column;
}

/* --------------------------- */

.contact-title-block {
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    height: 100px;
    background-color: #14213D;
    border-top: 3px solid #a8dadc;
    /* padding-left: 15%;
    padding-right: 20%; */
}

.contact-title {
    display: flex;
    align-items: center;
    margin-top: 0;
    padding-top: 15px;
    font-family: 'Montserrat', sans-serif;
    color: white;
    font-weight: 600;
    font-size: 40px;
    margin-left: 20px;
}

.contact-icon {
    color: white;
    font-size: 40px;
    margin-left: 20px;
}

/* ----------------------------- */

.contact-info-block {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 300px;
    background-color: #14213D;
}

.email-text {
    color: white;
    font-weight: 500;
}

.single-contact {
    display: flex;
    align-items: center;
}

.clipboard-icon {
    margin-left: 15px;
    width: 25px;
    height: 25px;
}

.clipboard-icon:hover {
    cursor: pointer;

}

.links-wrapper {
    margin-top: 20px;
    display: flex;
}
